@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap');

html,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Nunito, sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
  background-color: #001f1b !important;
  background-image: url('./assets/chumbi-pattern-dark.svg');
}

code {
  font-family: 'Nunito';
}

.toastContainer {
  margin-top: 70px;
  margin-right: 20px;
  @media (max-width: 768px) {
    margin-top: 0;
  }
}

@media screen and (max-width: 1280px) {
  body {
    height: 110%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
