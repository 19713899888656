.loadingspinner {
  pointer-events: none;
  width: 1.2em;
  height: 1.2em;
  border: 0.2em solid transparent;
  border-color: #eeeeee48;
  border-top-color: #fff;
  border-radius: 50%;
  margin-right: 10px;
  animation: loadingspin 1s linear infinite;
}

@keyframes loadingspin {
  100% {
    transform: rotate(360deg);
  }
}
